import { SyntheticEvent } from 'react'
import { Snackbar, Button, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

interface BannerProps {
    id: string
    isOpen: boolean
    message: string
    onRetry?: () => Promise<void>
    onClose: () => void
}

export const Banner = ({ id, isOpen, message, onRetry, onClose }: BannerProps) => {
    const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return
        onClose()
    }
    return (
        <Snackbar
            key={id}
            open={isOpen}
            message={message}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            action={
                <>
                    {onRetry && (
                        <Button color="secondary" onClick={() => void onRetry()}>
                            Retry
                        </Button>
                    )}
                    <IconButton color="inherit" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </>
            }
        />
    )
}
