export enum Path {
    root = '/',

    login = '/login',
    oauthLoginSuccess = '/oauthLoginSuccess',
    oauthLoginError = '/oauthLoginError',

    logout = '/logout',

    users = '/users',
    editUser = '/users/:userId',
    createUser = '/users/new',

    customers = '/customers',
    editCustomer = '/customers/:customerId',
    createCustomer = '/customers/new',

    licenses = '/licenses',
    editLicense = '/licenses/:licenseId',
    createLicense = '/licenses/new',

    installations = '/installations',
    installationDetails = '/customer/:customerId/installation/:installationId',

    billing = '/billing',
    billingDetails = '/billing/detail',
}

export const INITIAL_PATH = Path.installations

export function makeEditUserPath(userId: string) {
    return Path.editUser.replace(':userId', userId)
}
export function makeEditCustomerPath(customerId: string) {
    return Path.editCustomer.replace(':customerId', customerId)
}
export function makeEditLicensePath(licenseId: string) {
    return Path.editLicense.replace(':licenseId', licenseId)
}

export function makeInstallationDetailsPath({
    customerId,
    installationId,
}: {
    customerId: string
    installationId: string
}) {
    return Path.installationDetails.replace(':customerId', customerId).replace(':installationId', installationId)
}
