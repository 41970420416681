import { PlayCircle, PauseCircle, Settings, Delete } from '@mui/icons-material'
import { License, Role } from 'common-billing-server/types'
import { makeEditLicensePath } from '../../routes/path'
import { FailedOperation } from '../common/hooks/hook-failed-operation'
import { ApiOperation, TableActionItem, TableActions } from '../common/TableActions'

interface Props {
    license: License
    refreshTableFn?: () => void
    addFailedOperation: (failedOp: FailedOperation) => void
}

export const LicenseActions = ({ license, refreshTableFn, addFailedOperation }: Props) => {
    const editLicenseAction: TableActionItem = {
        label: 'Edit',
        dataTestId: 'edit-license',
        icon: <Settings />,
        onClick: makeEditLicensePath(license.id),
        minimumAllowedRole: Role.licenseAdmin,
    }
    const restoreLicenseAction: TableActionItem = {
        label: 'Re-enable license',
        dataTestId: 're-enable-license',
        icon: <PlayCircle />,
        onClick: makeReEnableLicenseOperation(license.id),
        minimumAllowedRole: Role.licenseAdmin,
    }
    const revokeLicenseAction: TableActionItem = {
        label: 'Revoke license',
        dataTestId: 'revoke-license',
        icon: <PauseCircle />,
        onClick: makeRevokeLicenseOperation(license.id),
        minimumAllowedRole: Role.licenseAdmin,
    }
    const deleteLicenseAction: TableActionItem = {
        label: 'Delete license',
        dataTestId: 'delete-license',
        icon: <Delete />,
        onClick: makeDeleteLicenseOperation(license.id),
        minimumAllowedRole: Role.licenseAdmin,
    }
    const actions = [
        editLicenseAction,
        license.invalidatedAt ? restoreLicenseAction : revokeLicenseAction,
        deleteLicenseAction,
    ]
    return <TableActions actions={actions} refreshTableFn={refreshTableFn} addFailedOperation={addFailedOperation} />
}

function makeRevokeLicenseOperation(licenseId: string): ApiOperation {
    return {
        id: 'revoke-license',
        name: 'Revoke license',
        exec: async (api) => {
            const result = await api.revokeLicenses({ licenseIds: [licenseId] })
            const failed = !result.succeeded.includes(licenseId)
            if (failed) {
                throw new Error(`Failed to revoke license`)
            }
        },
    }
}

function makeReEnableLicenseOperation(licenseId: string): ApiOperation {
    return {
        id: 're-enable-license',
        name: 'Re-enable license',
        exec: async (api) => {
            const result = await api.reEnableLicenses({ licenseIds: [licenseId] })
            const failed = !result.succeeded.includes(licenseId)
            if (failed) {
                throw new Error(`Failed to re-enable license`)
            }
        },
    }
}

function makeDeleteLicenseOperation(licenseId: string): ApiOperation {
    return {
        id: 'delete-license',
        name: 'Delete license',
        exec: async (api) => {
            const result = await api.deleteLicenses({ licenseIds: [licenseId] })
            const failed = !result.succeeded.includes(licenseId)
            if (failed) {
                throw new Error(`Failed to delete license`)
            }
        },
    }
}
