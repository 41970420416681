import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Paper, Tooltip, IconButton } from '@mui/material'
import { Cached, Delete, Add } from '@mui/icons-material'

import { useBillingApi } from '../../api/billing/billing-context'
import { Customer, ListCustomerSortableField, Role } from 'common-billing-server/types'
import { makeEditCustomerPath, Path } from '../../routes/path'
import { Column, DataFetchingPaginatedTable, RefreshableDataFetchingPaginatedTable } from '../common/Table'
import { Query } from 'common/query'
import { SortOrder } from 'common/api/v1/types'
import { useConfirmationDialog } from '../common/ConfirmationDialog'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'
import { ListPageToolbar } from '../common/ListPageToolbar'
import { RoleBasedComponent } from '../common/RoleBasedLink'
import { formatDate } from '../../format-date'
import { RotatingButton } from '../common/RotatingButton'
import { PaginatedDataFetchingFilter } from '../common/hooks/hook-paginated-data-fetching'

const columns: Column<Customer, ListCustomerSortableField>[] = [
    { title: 'Name', valueForColumn: (c) => c.name, sortField: ListCustomerSortableField.name },
    { title: 'Jeeves id', valueForColumn: (c) => c.jeevesCustomerNumber },
    { title: 'Billing type', valueForColumn: (c) => c.billingType },
    { title: 'Billing start (UTC)', valueForColumn: (c) => formatDate(c.billingStartsAt) },
]

export const ListCustomersPage = () => {
    const billingApi = useBillingApi()
    const { navigate } = useStatefulNavigate()
    const navigateToCustomer = (customer: Customer) => navigate(makeEditCustomerPath(customer.id))
    const tableRef = useRef<RefreshableDataFetchingPaginatedTable>(null)
    const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([])
    const showConfirmDeleteDialogFn = useConfirmationDialog()

    const { addFailedOperation, errorBanner } = useFailedOperationBanner()

    async function fetchCustomers({
        filter,
        order,
        ...query
    }: Query<PaginatedDataFetchingFilter<Record<string, never>>, SortOrder<ListCustomerSortableField>>) {
        try {
            return await billingApi.listCustomers({
                ...query,
                filter: { searchName: filter?.searchString },
                order,
            })
        } catch (error) {
            addFailedOperation({
                id: 'list-customers',
                message: 'Failed fetching customers',
                retryFn: tableRef.current?.refreshData,
            })
            throw error
        }
    }

    const onDeleteSelectedRowsClicked = () => {
        async function deleteSelectedCustomers(customers: Customer[]) {
            const ids = customers.map((c) => c.id)
            try {
                const { notDeleted } = await billingApi.deleteCustomers({ ids })
                if (notDeleted.length > 0) {
                    const customersNotDeleted = customers.filter(({ id }) => notDeleted.includes(id))
                    addFailedOperation({
                        id: notDeleted.join(','),
                        message: `Failed deleting ${notDeleted.length} customer(s)`,
                        retryFn: () => void deleteSelectedCustomers(customersNotDeleted),
                    })
                }
            } catch (error) {
                addFailedOperation({
                    id: ids.join(','),
                    message: `Failed deleting customers`,
                    error,
                    retryFn: () => void deleteSelectedCustomers(customers),
                })
            }

            tableRef.current?.refreshData()
        }

        showConfirmDeleteDialogFn(() => {
            void deleteSelectedCustomers(selectedCustomers)
        }, `Delete ${selectedCustomers.length} customer(s)?`)
    }

    return (
        <>
            <ListPageToolbar
                title={'Customers'}
                numberOfSelectedItems={selectedCustomers.length}
                selectedActions={[
                    <Tooltip key="delete" title="Delete">
                        <IconButton onClick={onDeleteSelectedRowsClicked}>
                            <Delete />
                        </IconButton>
                    </Tooltip>,
                ]}
                actions={[
                    <RotatingButton
                        key="refresh"
                        tooltip="Refresh"
                        onClick={() => tableRef.current?.refreshData()}
                        Component={<Cached />}
                    />,
                    <RoleBasedComponent key="create" minimumAllowedRole={Role.admin}>
                        <NavLink to={Path.createCustomer}>
                            <Tooltip title="Create new customer">
                                <IconButton>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </NavLink>
                    </RoleBasedComponent>,
                ]}
            />

            <Paper>
                <DataFetchingPaginatedTable
                    myRef={tableRef}
                    api={fetchCustomers}
                    columns={columns}
                    emptyRowsMessage={'No customers found'}
                    onRowClicked={navigateToCustomer}
                    onRowsSelected={setSelectedCustomers}
                    searchParameters={{ searchBarPlaceholder: 'Search customer by name or Jeeves id...' }}
                />
            </Paper>

            {errorBanner}
        </>
    )
}
