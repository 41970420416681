import { SyntheticEvent, useEffect, useState } from 'react'
import { useMatch, useParams, useResolvedPath } from 'react-router-dom'
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material'

import { useBillingApi } from '../../api/billing/billing-context'
import { ValidatedInput } from '../common/InputWithValidation'
import { NewUser, Role, UserManagementSystem } from 'common-billing-server/types'
import { Path } from '../../routes/path'
import { email, MinLength, notEmpty } from '../../validators'
import { commonTextFieldProps } from '../../theme/common-styles'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { ButtonWithLoadingIndicator } from '../common/ButtonWithLoadingIndicator'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'

export const CreateEditUserPage = () => {
    const billingApi = useBillingApi()
    const { navigateBack } = useStatefulNavigate()
    const urlParams = useParams()
    const userId: string | undefined = urlParams.userId
    const resolvedPath = useResolvedPath(Path.editUser) // Get a path template from the current URL, e.g. /users/0ff5a8e5-c373-43bd-9edd-a6b5d4933981 --> users/:userId
    const isEditingUser = !!useMatch({ path: resolvedPath.pathname, end: true }) && !!userId

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [role, setRole] = useState(Role.invoiceOnly)
    const [managedBy, setManagedBy] = useState(UserManagementSystem.billingServer)
    const isUserManagedByExternalSystem = managedBy !== UserManagementSystem.billingServer
    const [isLoading, setIsLoading] = useState(false)
    const [hasSubmittedForm, setHasSubmittedForm] = useState(false)
    const [validationErrors, setValidationErrors] = useState({
        username: undefined as string | undefined,
        password: undefined as string | undefined,
    })
    const updateValidationError = (key: keyof typeof validationErrors, value: string | undefined) => {
        setValidationErrors((prevState) => ({ ...prevState, [key]: value }))
    }
    const formContainsInvalidInput = Object.values(validationErrors).some((error) => error)
    const { addFailedOperation, removeFailedOperation, errorBanner } = useFailedOperationBanner()

    useEffect(() => {
        const operationId = 'fetch-user'

        async function fetchUser(id: string) {
            removeFailedOperation(operationId)
            setIsLoading(true)
            try {
                const user = await billingApi.getUser(id)
                setUsername(user.username)
                setRole(user.role)
                setManagedBy(user.managedBy)
            } catch (error) {
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching user',
                    error,
                })
            }
            setIsLoading(false)
        }

        if (isEditingUser) {
            void fetchUser(userId)
        }
    }, [userId, isEditingUser])

    const createOrUpdateUser = async (event: SyntheticEvent) => {
        event.preventDefault()

        const operationId = 'update-user'
        removeFailedOperation(operationId)
        setHasSubmittedForm(true)
        if (formContainsInvalidInput) {
            return
        }
        try {
            setIsLoading(true)
            const user: NewUser = { username, password, role }
            if (isEditingUser) {
                await billingApi.updateUser(userId!, user)
            } else {
                await billingApi.createUser(user)
            }
            setIsLoading(false)
            navigateBack({ replace: true })
        } catch (error) {
            setIsLoading(false)
            addFailedOperation({
                id: operationId,
                message: `Failed ${isEditingUser ? 'updating' : 'creating'} user`,
                error,
            })
        }
    }

    return (
        <>
            <Toolbar>
                <Typography variant="h6" component="div">
                    {isEditingUser ? 'Edit' : 'Create'} user
                </Typography>
            </Toolbar>

            <Paper style={{ margin: '20px' }}>
                <form
                    onSubmit={(e) => void createOrUpdateUser(e)}
                    style={{
                        padding: '20px',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <ValidatedInput
                        {...commonTextFieldProps()}
                        type="text"
                        label={'Email'}
                        autoFocus={true}
                        disabled={isLoading || isUserManagedByExternalSystem}
                        validators={[notEmpty, email]}
                        value={username}
                        onChange={setUsername}
                        onValidationError={(error) => updateValidationError('username', error)}
                        showErrorDespiteUntouched={hasSubmittedForm}
                    />
                    {!isUserManagedByExternalSystem && (
                        <ValidatedInput
                            {...commonTextFieldProps()}
                            type="password"
                            label={'Password'}
                            autoComplete={'new-password'}
                            disabled={isLoading || isUserManagedByExternalSystem}
                            validators={isEditingUser ? [new MinLength(8)] : [notEmpty, new MinLength(8)]}
                            value={password}
                            onChange={setPassword}
                            onValidationError={(error) => updateValidationError('password', error)}
                            showErrorDespiteUntouched={hasSubmittedForm}
                        />
                    )}

                    <TextField
                        {...commonTextFieldProps()}
                        type="text"
                        label={'Managed by'}
                        disabled={true}
                        value={managedBy}
                    />
                    <FormControl disabled={isLoading} fullWidth style={{ margin: '8px 0px 0px 0px' }}>
                        <FormLabel id="role-radio-button-group-label">Role</FormLabel>
                        <RadioGroup row aria-labelledby="row-radio-button-group-label" name="row-radio-button-group">
                            {Object.values(Role).map((r) => (
                                <FormControlLabel
                                    key={r}
                                    value={r}
                                    checked={r === role}
                                    onChange={(_, checked) => {
                                        if (checked) setRole(r)
                                    }}
                                    control={<Radio />}
                                    label={r}
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>

                    <ButtonWithLoadingIndicator
                        title={isEditingUser ? 'Update' : 'Create'}
                        isLoading={isLoading}
                        disabled={formContainsInvalidInput || isLoading}
                        type="submit"
                        style={{ width: '25%', margin: '12px 0px 20px 0px' }}
                    />
                    {errorBanner}
                </form>
            </Paper>
        </>
    )
}
