import { useState } from 'react'
import { useTheme, Container, Typography } from '@mui/material'
import netiLogoWhite from '../../assets/netinsight-logo-color@3x.png'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { UsernamePasswordLoginPage } from './UsernamePasswordLoginPage'
import { AzureLoginPage } from './AzureLoginPage'

export const LoginPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const failedOperationHook = useFailedOperationBanner()

    return (
        <Container component="main" maxWidth="xs">
            <LoginPageIcon />

            <UsernamePasswordLoginPage
                isLoadingState={{ isLoading, setIsLoading }}
                failedOperationHook={failedOperationHook}
            />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography>OR</Typography>
            </div>

            <AzureLoginPage isLoadingState={{ isLoading, setIsLoading }} failedOperationHook={failedOperationHook} />
            {failedOperationHook.errorBanner}
        </Container>
    )
}

function LoginPageIcon() {
    const theme = useTheme()
    return (
        <div style={{ margin: theme.spacing(6, 0) }}>
            <img
                src={netiLogoWhite}
                style={{
                    height: '44px',
                    margin: '0 auto',
                    display: 'block',
                }}
                alt="Net Insight logo"
            />
        </div>
    )
}
