import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { endOfDayUTC } from 'common/api/v1/helpers'
import { parseISOUTC, utcToLocal } from '../../format-date'

type DateRangePickerProps = {
    minDate?: Date
    startDateString: string
    endDateString: string
    maxDate?: Date
    onChange: (key: 'startDate' | 'endDate', value: Date | null) => void
}

/** DateRangePicker in UTC */
export const DateRangePicker = ({
    minDate = new Date('2020-01-01'),
    startDateString,
    endDateString,
    maxDate,
    onChange,
}: DateRangePickerProps) => {
    const [openFrom, setOpenFrom] = useState(false)
    const [openTo, setOpenTo] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        searchParams.set('startDate', startDateString)
        searchParams.set('endDate', endDateString)
        setSearchParams(searchParams, { replace: true })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker<Date>
                open={openFrom}
                onOpen={() => setOpenFrom(true)}
                onClose={() => setOpenFrom(false)}
                label="From"
                minDate={minDate}
                maxDate={parseISOUTC(endDateString)}
                value={parseISOUTC(startDateString)}
                onChange={(value) => onChange('startDate', value ? utcToLocal(value) : null)}
                format="yyyy-MM-dd"
                slotProps={{
                    textField: {
                        onClick: () => setOpenFrom(true),
                    },
                }}
                sx={{ marginX: 1 }}
            />
            <DatePicker<Date>
                open={openTo}
                onOpen={() => setOpenTo(true)}
                onClose={() => setOpenTo(false)}
                label="To"
                minDate={parseISOUTC(startDateString)}
                maxDate={maxDate}
                value={parseISOUTC(endDateString)}
                onChange={(value) => onChange('endDate', value ? endOfDayUTC(utcToLocal(value)) : null)}
                format="yyyy-MM-dd"
                slotProps={{
                    textField: {
                        onClick: () => setOpenTo(true),
                    },
                }}
                sx={{ marginX: 1 }}
            />
        </LocalizationProvider>
    )
}
