import { CircularProgress } from '@mui/material'

export function LoadingIndicator() {
    return (
        <div style={{ backgroundColor: 'rgb(245, 245, 245)', padding: '8px' }}>
            <CircularProgress
                style={{
                    position: 'relative',
                    left: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            />
        </div>
    )
}
