import { ReactNode, Fragment } from 'react'
import { Toolbar, Typography } from '@mui/material'

import { FlexFiller, classes as commonStyles } from '../../theme/common-styles'

interface Props<_T> {
    title: string
    numberOfSelectedItems: number
    actions: ReactNode[]
    selectedActions: ReactNode[]
}
export function ListPageToolbar<T>({ title, numberOfSelectedItems, actions, selectedActions }: Props<T>) {
    const hasSelectedRows = numberOfSelectedItems > 0
    return (
        <Toolbar sx={hasSelectedRows ? commonStyles.toolbarHighlighted : commonStyles.toolbar}>
            <Typography variant="h6" component="div">
                {hasSelectedRows ? `${numberOfSelectedItems} selected` : title}
            </Typography>
            <FlexFiller />
            {hasSelectedRows && selectedActions.map((c, i) => <Fragment key={i}>{c}</Fragment>)}
            {actions.map((c, i) => (
                <Fragment key={i}>{c}</Fragment>
            ))}
        </Toolbar>
    )
}
