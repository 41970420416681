import { Delete, RestoreFromTrash, Info } from '@mui/icons-material'

import { Installation, Role } from 'common-billing-server/types'
import { makeInstallationDetailsPath } from '../../routes/path'
import { FailedOperation } from '../common/hooks/hook-failed-operation'
import { ApiOperation, TableActionItem, TableActions } from '../common/TableActions'

interface Props {
    installation: Installation
    refreshTableFn?: () => void
    addFailedOperation: (failedOp: FailedOperation) => void
}

export const InstallationActions = ({ installation, addFailedOperation, refreshTableFn }: Props) => {
    const restoreAction: TableActionItem = {
        label: 'Restore installation',
        dataTestId: 'restore-installation',
        icon: <RestoreFromTrash />,
        onClick: makeRestoreInstallationOperation(installation.id),
        minimumAllowedRole: Role.licenseAdmin,
    }
    const deleteAction: TableActionItem = {
        label: 'Delete installation',
        dataTestId: 'delete-installation',
        icon: <Delete />,
        onClick: makeDeleteInstallationOperation(installation.id),
        minimumAllowedRole: Role.licenseAdmin,
    }
    const actions = [
        {
            label: 'View',
            dataTestId: 'view-installation',
            icon: <Info />,
            onClick: makeInstallationDetailsPath({
                installationId: installation.id,
                customerId: installation.customer.id,
            }),
            minimumAllowedRole: Role.invoiceOnly,
        },
        installation.deleted ? restoreAction : deleteAction,
    ]
    return <TableActions actions={actions} refreshTableFn={refreshTableFn} addFailedOperation={addFailedOperation} />
}

function makeDeleteInstallationOperation(installationId: string): ApiOperation {
    return {
        name: 'Delete installation',
        id: 'delete-installation',
        exec: async (api) => await api.deleteInstallation(installationId),
    }
}
function makeRestoreInstallationOperation(installationId: string): ApiOperation {
    return {
        name: 'Restore installation',
        id: 'restore-installation',
        exec: async (api) => await api.restoreInstallation(installationId),
    }
}
