import { useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Paper, Tooltip, IconButton } from '@mui/material'
import { Delete, Add, Cached } from '@mui/icons-material'

import { useBillingApi } from '../../api/billing/billing-context'
import { ListUserSortableField, Role, User } from 'common-billing-server/types'
import { makeEditUserPath, Path } from '../../routes/path'
import { Column, DataFetchingPaginatedTable, RefreshableDataFetchingPaginatedTable } from '../common/Table'
import { Query } from 'common/query'
import { SortOrder } from 'common/api/v1/types'
import { useConfirmationDialog } from '../common/ConfirmationDialog'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { ListPageToolbar } from '../common/ListPageToolbar'
import { RoleBasedComponent } from '../common/RoleBasedLink'
import { RotatingButton } from '../common/RotatingButton'
import { PaginatedDataFetchingFilter } from '../common/hooks/hook-paginated-data-fetching'

const columns: Column<User, ListUserSortableField>[] = [
    { title: 'Username', valueForColumn: (element) => element.username, sortField: ListUserSortableField.userName },
    { title: 'Role', valueForColumn: (element) => element.role, sortField: ListUserSortableField.role },
    { title: 'Managed by', valueForColumn: (element) => element.managedBy },
]

export const ListUsersPage = () => {
    const billingApi = useBillingApi()
    const navigate = useNavigate()
    const navigateToUser = (user: User) => navigate(makeEditUserPath(user.id))
    const tableRef = useRef<RefreshableDataFetchingPaginatedTable>(null)
    const [selectedUsers, setSelectedUsers] = useState<User[]>([])
    const showConfirmDeleteDialogFn = useConfirmationDialog()

    const { addFailedOperation, errorBanner } = useFailedOperationBanner()

    async function fetchUsers({
        filter,
        order,
        ...query
    }: Query<PaginatedDataFetchingFilter<Record<string, never>>, SortOrder<ListUserSortableField>>) {
        try {
            return await billingApi.listUsers({
                ...query,
                filter: { username: filter?.searchString },
                order,
            })
        } catch (error) {
            addFailedOperation({
                id: 'list-users',
                message: 'Failed fetching users',
                retryFn: tableRef.current?.refreshData,
            })
            throw error
        }
    }

    const onDeleteSelectedRowsClicked = () => {
        async function deleteSelectedUsers(users: User[]) {
            for (const user of users) {
                try {
                    await billingApi.deleteUser(user.id) // TODO: Create deleteUsers endpoint
                } catch (error) {
                    addFailedOperation({
                        id: user.id,
                        message: `Failed deleting user "${user.username}"`,
                        error,
                        retryFn: () => {
                            void deleteSelectedUsers([user])
                        },
                    })
                }
            }

            tableRef.current?.refreshData()
        }

        showConfirmDeleteDialogFn(() => {
            void deleteSelectedUsers(selectedUsers)
        }, `Delete ${selectedUsers.length} user(s)?`)
    }

    return (
        <>
            <ListPageToolbar
                title={'Users'}
                numberOfSelectedItems={selectedUsers.length}
                selectedActions={[
                    <Tooltip key="delete" title="Delete">
                        <IconButton onClick={onDeleteSelectedRowsClicked}>
                            <Delete />
                        </IconButton>
                    </Tooltip>,
                ]}
                actions={[
                    <RotatingButton
                        key="refresh"
                        tooltip="Refresh"
                        onClick={() => tableRef.current?.refreshData()}
                        Component={<Cached />}
                    />,
                    <RoleBasedComponent key="create" minimumAllowedRole={Role.licenseAdmin}>
                        <NavLink to={Path.createUser}>
                            <Tooltip title="Create new user">
                                <IconButton>
                                    <Add />
                                </IconButton>
                            </Tooltip>
                        </NavLink>
                    </RoleBasedComponent>,
                ]}
            />

            <Paper>
                <DataFetchingPaginatedTable
                    myRef={tableRef}
                    api={fetchUsers}
                    columns={columns}
                    emptyRowsMessage={'No users found'}
                    onRowClicked={navigateToUser}
                    onRowsSelected={setSelectedUsers}
                    searchParameters={{ searchBarPlaceholder: 'Search username...' }}
                />
            </Paper>

            {errorBanner}
        </>
    )
}
