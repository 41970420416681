/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState, useContext, ReactNode, FC } from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, Button, ButtonProps } from '@mui/material'

interface ActionButtonProps extends ButtonProps {
    text?: string
}
interface Actions {
    cancel?: ActionButtonProps
    ok?: ActionButtonProps
}
interface ConfirmDialogContext {
    showConfirmationFn: (
        callback: () => void,
        comp: ReactNode,
        buttonProps?: Actions,
        cancelCallback?: () => void
    ) => void
    hideConfirmationFn: () => void
    isShown: boolean
    onOkAction?: (() => void) | undefined
    onCancelAction?: (() => void) | undefined
    comp?: ReactNode
    buttonProps?: Actions
}
export const ConfirmDialogContext = createContext<ConfirmDialogContext>({
    showConfirmationFn: () => {},
    hideConfirmationFn: () => {},
    isShown: false,
    onOkAction: () => {},
    onCancelAction: () => {},
    comp: undefined,
    buttonProps: undefined,
})

type Props = {
    children: ReactNode
}

/**
 * Provider to use to show simple confirmation dialog
 * To invoke call useConfirmationDialog from common utils
 */
export const ConfirmDialogProvider: FC<Props> = ({ children }) => {
    const [state, setState] = useState<
        Pick<ConfirmDialogContext, 'isShown' | 'onOkAction' | 'comp' | 'buttonProps' | 'onCancelAction'>
    >({
        isShown: false,
        onOkAction: undefined,
        comp: undefined,
        buttonProps: undefined,
    })
    const value = {
        showConfirmationFn: (
            onOkAction: () => void,
            comp: ReactNode,
            buttonProps?: Actions,
            onCancelAction?: () => void
        ) =>
            setState({
                isShown: true,
                onOkAction,
                onCancelAction,
                comp,
                buttonProps,
            }),
        hideConfirmationFn: () =>
            setState({
                isShown: false,
                onOkAction: state.onOkAction,
                comp: state.comp,
                buttonProps: state.buttonProps,
            }),
        ...state,
    }
    return (
        <ConfirmDialogContext.Provider value={value}>
            {children}
            <ConfirmDialog />
        </ConfirmDialogContext.Provider>
    )
}

const ConfirmDialog = () => {
    const { hideConfirmationFn, isShown, onOkAction, onCancelAction, comp, buttonProps } =
        useContext(ConfirmDialogContext)
    const { text: textOk = 'OK', ...restOk } = (buttonProps || { ok: { text: 'OK' } }).ok || {}
    const { text: textCancel = 'Cancel', ...restCancel } = (buttonProps || { cancel: { text: 'Cancel' } }).cancel || {}
    const onCancel = () => {
        onCancelAction?.()
        hideConfirmationFn()
    }
    return (
        <Dialog
            open={isShown}
            onClose={(_event, reason) => {
                if (reason === 'escapeKeyDown') {
                    onCancel()
                }
            }}
            onKeyUp={(e) => {
                const KEYCODE_ENTER = 13
                if (e.keyCode === KEYCODE_ENTER) {
                    onOkAction && onOkAction()
                    hideConfirmationFn()
                }
            }}
            data-test-id="confirm-dialog"
        >
            <DialogContent>
                <DialogContentText component="div">{comp}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onCancel}
                    data-test-id="cancel-btn"
                    {...restCancel}
                >
                    {textCancel}
                </Button>
                <Button
                    data-test-id="ok-btn"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        onOkAction && onOkAction()
                        hideConfirmationFn()
                    }}
                    {...restOk}
                >
                    {textOk}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

/**
 * returns the function to be called to invoke common confirmation dialog from the context
 * function parameters are
 *  callback: callback to call on OK click,
 *  comp: component to show inside the dialog (confirm text),
 *  buttonProps?: {ok: {text, ...other button props}, cancel: {text, ...other button props}},
 *  cancelCallback?: callback to call on Cancel click,
 */
export const useConfirmationDialog = () => {
    const { showConfirmationFn } = useContext(ConfirmDialogContext)
    return showConfirmationFn
}
