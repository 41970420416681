import { useEffect, useState } from 'react'
import { useAuth } from './Auth'
import { Container, useTheme } from '@mui/material'
import { INITIAL_PATH } from '../../routes/path'
import netiLogoWhite from '../../assets/netinsight-logo-color@3x.png'
import { useFailedOperationBanner } from '../common/hooks/hook-failed-operation'
import { useStatefulNavigate } from '../common/hooks/hook-stateful-navigate'

export const OauthLoginSuccessPage = () => {
    const auth = useAuth()
    const { navigate } = useStatefulNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const { addFailedOperation, removeFailedOperation, errorBanner } = useFailedOperationBanner()

    useEffect(() => {
        const fetchLoggedInUser = async () => {
            if (isLoading) {
                return
            }
            const operationId = 'fetchLoggedInUser'
            removeFailedOperation(operationId)

            try {
                setIsLoading(true)
                await auth.refreshUser()
                navigate(INITIAL_PATH, { replace: true })
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(`Err: ${error}`, error)
                addFailedOperation({
                    id: operationId,
                    message: 'Failed fetching logged in user',
                    error,
                    retryFn: fetchLoggedInUser,
                })
            }
            setIsLoading(false)
        }

        void fetchLoggedInUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <LoginPageIcon />
            {errorBanner}
        </Container>
    )
}

function LoginPageIcon() {
    const theme = useTheme()
    return (
        <div style={{ margin: theme.spacing(6, 0) }}>
            <img
                src={netiLogoWhite}
                style={{
                    height: '44px',
                    margin: '0 auto',
                    display: 'block',
                }}
                alt="Net Insight logo"
            />
        </div>
    )
}
