import { ReactNode, FC } from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { lightTheme } from './themes'

type Props = {
    children: ReactNode
}

export const ThemeSwitcher: FC<Props> = ({ children }) => {
    function getCurrentTheme() {
        const useLightTheme = true // TODO: Add logic and return darkTheme
        return useLightTheme ? lightTheme : lightTheme
    }
    const currentTheme = getCurrentTheme()
    return (
        <ThemeProvider theme={currentTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    )
}
